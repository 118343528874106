// 表格中时间转化
import Moment from "moment";
function timeFormatter(time, format = "YYYY-MM-DD") {
  if (time) {
    return Moment(time).format(format);
  } else {
    return "--";
  }
}

export { timeFormatter };
